/**
 *  This file is replaced in non development mode by appropriate
 *  file configuration.
 */

/**
 * Development/Test (default)
 *
 * A development/test configuration of an application with
 * all sub applications modules included.
 *
 * This configuration is byu default used when we use ng b / ng serve
 * without any flags and configuration specified.
 */
export const environment = {
    name: '(default)',
    production: false,
    version: '1.7.0',
    secureCookie: false,

    applicationBaseUrl: 'https://heating-systems-overview-integration.viessmann.com/',

    /**
     * Main API-Middleware URL.
     */
    //apiUrl: 'http://localhost:8080/v1',
    apiUrl: 'https://api-integration.viessmann.com/b2b-service-and-maintenance/heating-systems-overview/v1',

    /**
     * Full URL for getting CSRF Token for Authorization.
     */
    csrfAuthUrl: 'https://api-integration.viessmann.com/auth/v1/saml/csrf',

    /**
     * Full URL for user information.
     */
    usersMe: 'https://api-integration.viessmann.com/users/v1/users/me?sections=roles&sections=identity&sections=sales',

    /**
     * Full URL for header.
     */
    headerBaseUrl: 'https://api-integration.viessmann.com',

    /**
     * Full URL for account data via header.
     */
    headerAccountAppUrl: 'https://account-integration.viessmann.com',

    /**
     * URL for Vibooks with query param
     */
    viBooks: 'https://vibooks-integration.viessmann.com/{countryCode}/{languageCode}?query={systemId}&utm_source=heating_systems_overview',

    /**
     * Full URL for Viguide installations
     */
    viGuideBaseUrl: 'https://viguide-integration.viessmann.com/installations?utm_source=heating_systems_overview',

    /**
     * Full URL for Viguide installations
     */
    viGuide: 'https://viguide-integration.viessmann.com/installations/{installationId}?utm_source=heating_systems_overview',

    /**
     * Full URL for heating-system-registration-b2b for specific equipment
     */
    heatingSystemRegistrationEqui: 'https://heating-system-registration-b2b-integration.viessmann.com/open/{systemId}?utm_source=heating_systems_overview_change',

    /**
     * Full URL for heating-system-registration-b2b
     */
    heatingSystemRegistrationSerialnumber:  'https://heating-system-registration-b2b-integration.viessmann.com?serialnumber={serialNumber}&utm_source=heating_systems_overview_create',

    /**
     * Full URL for heating-system-registration-b2b
     */
    heatingSystemRegistrationSerialnumberChange:  'https://heating-system-registration-b2b-integration.viessmann.com?serialnumber={serialNumber}&utm_source=heating_systems_overview_change',

    /**
     * Full URL for heating-system-registration-b2b
     */
    heatingSystemRegistrationBase:  'https://heating-system-registration-b2b-integration.viessmann.com?utm_source=heating_systems_overview_create',

    /**
     * Full URL for heating-system-registration-b2b API
     */
    heatingSystemRegistrationApi: 'https://api-integration.viessmann.com/b2b-service-and-maintenance/heating-system-reg-b2b/api/',

    /**
     * URL data protection declaration
     */
    dataProtection: 'https://legal-policies.viessmann.com/{b2x}/common/privacy-policy.html',

    /**
     * URL imprint
     */
    imprint: 'https://legal-policies.viessmann.com/{b2x}/common/imprint.html',

    IAMConfig: {
        auth: {
            baseUrl: 'https://api-integration.viessmann.com/',
            appId: 'heating-systems-overview'
        }
    },


    /**
     * URL Shop by material number
     */
    shopUrl: 'https://shop-test.viessmann.com/details/',

    /**
     * URL Shop by serial number
     */
    shopSerialUrl: 'https://shop-test.viessmann.com/service',

    /**
     * URL Shop by serial number
     */
    shopOfferUrl: 'https://shop-test.viessmann.com/offers',

    /**
     * URL Partner-Portal Country and Language dependent
     */
    partnerPortalUrl: 'https://partnerportal-staging.viessmann.com/{countryCode}/{languageCode}/',

    /**
     * Partner-Portal TD-Form addition DE
     */
    partnerPortalDEFormGeneral: 'installation-und-service/anfragen-an-den-td.html',

    /**
     * Partner-Portal TD-Form addition DE
     */
    partnerPortalDEFormService: 'installation-und-service/anfragen-an-den-td.html?orderData.serviceOperationNumber=',
    /**
     * Partner-Portal TD-Form addition NL
     */
    partnerPortalNLFormGeneral: 'td-technische-dienst/aanvragen-aan-de-technische-dienst.html',

    /**
     * Partner-Portal TD-Form addition NL
     */
    partnerPortalNLFormService: 'td-technische-dienst/aanvragen-aan-de-technische-dienst/td-vraag-over-interventie.html?orderData.orderNumber=',

    /**
     * Partner-Portal TD-Form addition FR
     */
    partnerPortalFRFormGeneral: 'installation-und-service/demandes-au-service-technique.html',

    /**
     * Partner-Portal TD-Form addition FR
     */
    partnerPortalFRFormService: 'installation-und-service/demandes-au-service-technique/td-question-sur-une-intervention.html?orderData.orderNumber=',

    /**
     * Partner-Portal TD-Form addition IT
     */
    partnerPortalITFormGeneral: 'Servizio_autorizzato_assistenza/richiesta_di_supporto.html',

    /**
     * Partner-Portal TD-Form addition IT
     */
    partnerPortalITFormService: 'Servizio_autorizzato_assistenza/richiesta_di_supporto.html?orderData.serviceOperationNumber=',

    /**
     * URL Partner-Portal
     */
    partnerPortalBaseUrl: 'https://partnerportal-staging.viessmann.com',

    /**
     * URL service-configurator
     */
    serviceConfiguratorUrl: 'https://sc-integration.viessmann.com/configuration/new?equipmentId={equipmentId}&utm_source=heating_systems_overview',

    /**
     * URL For SAP-Maintenance Information
     */
    statusPage: 'https://api-integration.viessmann.com/status/v1/apps/ydn2lvc56h36/summary',

    /**
     * Flag if SAP-Status should be checked
     */
    statusCheckActive: false,

    /**
     * URL for registration b2c
     */
    endCustomerRegistration: 'https://account-integration.viessmann.com/register-end-customer?address=true',

    /**
     * URL for registration b2b
     */
    customerRegistration: 'https://account-integration.viessmann.com/register?hideNoCompanyNumberOption=true',

    translationUrl: 'https://api-integration.viessmann.com/translations/v2/phraseapp-proxy/projects/a07a6c3a6e4b327945561d46d852905c/locales/',

    /**
     * URL Fiori
     */
    fioriEquipmentUrl: 'https://fiori-test.viessmann.com/fiori#ZCS_EQUI_MASTER_DATA_SHEET-display?equipment={equipment}&sap-app-origin-hint=&/equipment/{equipment}',

    /**
     * URL Account Personal Data
     */
    accountMaintenanceUrl: 'https://account-integration.viessmann.com/personal-data?redirect={redirect}&originApp={title}',

    /**
     * URL Account Personal Data
     */
    accountContactMaintenanceUrl: 'https://account-integration.viessmann.com/notifications?redirect={redirect}&originApp={title}',

    /**
     * URL Become Partner DE
     */
    becomePartnerUrlDE: 'https://www.viessmann.de/de/kontakt/anfragen/fachpartner-werden.html',

    /**
     * URL Become Partner FR
     */
    becomePartnerUrlFR: 'https://www.viessmann.fr/fr/professionnels/devenir-partenaire/form-devenir-partenaire.html',

    /**
     * URL Become Partner BE
     */
    becomePartnerUrlBE: 'https://www.viessmann.be/nl/contact/aanvraag-installateur-worden.html',

    /**
     * URL Become Partner LU
     */
    becomePartnerUrlLUde: 'https://www.viessmann.lu/de/kontakt/fachpartner-werden.html',

    /**
     * URL Become Partner LU/fr
     */
    becomePartnerUrlLUfr: 'https://www.viessmann.lu/fr/contact/demande-devenir-installateur.html',

    /**
     * URL Become Partner NL
     */
    becomePartnerUrlNL: 'https://www.viessmann.nl/nl/contact/aanvraag-installateur-worden.html',

    /**
     * URL Become Partner IT
     */
    becomePartnerUrlIT: 'https://www.viessmann.it/it/contattaci/residenziale-professionista.html',

    /**
     * URL Become Partner SK
     */
    becomePartnerUrlSK: 'https://www.viessmann-partner.sk/sk/register-public',

    /**
     * URL Become Partner CZ
     */
    becomePartnerUrlCZ: 'https://www.viessmann-partner.cz/CZ/register-public',

    /**
     * URL Become Partner DK
     */
    becomePartnerUrlDK: 'https://www.viessmann.dk/da/din-forespoergsel.html',

    /**
     * URL Become Partner AT
     */
    becomePartnerUrlAT: 'https://www.viessmann.at/de/kontakt/formulare/fachpartner-werden.html',

    /**
     * URL UserPortal
     */
    userPortalUrl: 'https://home-integration.viessmann.com',

    /**
     * URL UserPortal de
     */
    userPortalUrlDe: 'https://home-integration.viessmann.com/{countryCode}/{languageCode}/anlagen.html',

    /**
     * URL UserPortal fr
     */
    userPortalUrlFr: 'https://home-integration.viessmann.com/{countryCode}/{languageCode}/installations.html',

    /**
     * URL UserPortal it
     */
    userPortalUrlIt: 'https://home-integration.viessmann.com/{countryCode}/{languageCode}/sistemi.html',

    /**
     * URL Supportlink for Partner Portal AT
     */
    atPartnerPortalSupportLink: 'https://partnerportal-integration.viessmann.com/at/de/support/online-anfrage-stellen.html?additionalInfo.description=Anlagen%C3%BCbersicht'


};
